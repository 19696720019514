<template lang="html">
  <div class="">
    <div class="main-container">
      <div class="h2">
        <button class="btn-back" type="button" name="button" @click="$router.go(-1)">
          <span class="icon-w-back"></span>
        </button>
        <span>性别</span>
      </div>
      <div class="" v-if="userInfo">
        <div class="form-radio-group">
          <input id="male" class="form-radio" type="radio" v-model="userInfo.sex" value="1">
          <label for="male">男</label>
        </div>
        <div class="form-radio-group">
          <input id="female" class="form-radio" type="radio" v-model="userInfo.sex" value="2">
          <label for="female">女</label>
        </div>
      </div>
      <div class="success-msg m-t" v-if="message">
        {{message}}
      </div>
      <div class="error-msg m-t" v-if="err">
        {{err}}
      </div>
      <div class="btn-group m-t">
        <button type="button" @click="onSubmit" class="btn btn-success btn-md">确定</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { AccountFactory } from '../../factory/AccountFactory';

export default {
  data: () => ({
    message: '',
    err: '',
  }),
  computed: {
    ...mapGetters([
      'userInfo',
    ]),
  },
  methods: {
    // 需要快速完成，所以不做更多封装
    onSubmit() {
      this.message = '';
      this.err = '';
      const data = {
        sex: this.userInfo.sex,
      };
      AccountFactory.updateProfile(data).then((res) => {
        if (res) {
          this.message = '修改成功';
        }
      }).catch((e) => {
        this.err = e.message;
      });
    },
  },
};
</script>

<style lang="css" scoped>
.info-avatar {
  width: 80px;
  height: 80px;
  overflow: hidden;
}
.info-avatar > img {
  width: 100%;
}
</style>
